// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { client } from "@/sanity/client";
import { json } from "@remix-run/node";
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import { Suspense, createContext, lazy } from "react";
import { CATEGORIES_QUERY, POSTS_QUERY } from "@/sanity/queries";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import stylesheet from "@/tailwind.css";
import React from "react";
export const AppContext = createContext({
  companyId: null,
  setCompanyId: () => {},
  phone: null,
  setPhone: () => {},
  email: null,
  setEmail: () => {},
  errors: {},
  setErrors: () => {}
});
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}];
const LiveVisualEditing = lazy(_c = () => import("@/components/LiveVisualEditing"));
_c2 = LiveVisualEditing;
export const loader = async ({
  params
}) => {
  const categories = await client.fetch(CATEGORIES_QUERY, params);
  const posts = await client.fetch(POSTS_QUERY, params);
  return json({
    categories,
    params,
    posts,
    ENV: {
      SANITY_STUDIO_PROJECT_ID: process.env.SANITY_STUDIO_PROJECT_ID,
      SANITY_STUDIO_DATASET: process.env.SANITY_STUDIO_DATASET,
      SANITY_STUDIO_URL: process.env.SANITY_STUDIO_URL,
      SANITY_STUDIO_STEGA_ENABLED: process.env.SANITY_STUDIO_STEGA_ENABLED
    }
  });
};
export default function App() {
  _s();
  const {
    categories,
    params,
    ENV,
    posts
  } = useLoaderData();
  const [companyId, setCompanyId] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>Meglerhjelpen</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Navbar data={categories} slug={params} />
        {/* <div className={"container mx-auto p-2 sm:px-6 lg:px-8 max-w-7xl"}> */}
        <AppContext.Provider value={{
        companyId,
        setCompanyId,
        phone,
        setPhone,
        email,
        setEmail,
        setErrors,
        errors
      }}>
          <Outlet />
        </AppContext.Provider>
        <ScrollRestoration />
        <script dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(ENV)}`
      }} />

        {ENV.SANITY_STUDIO_STEGA_ENABLED ? <Suspense>
            <LiveVisualEditing />
          </Suspense> : null}
        <Scripts />
        <LiveReload />
        <Footer categories={categories} posts={posts} />
      </body>
    </html>;
}
_s(App, "Yp4P6bq+w1aqzAjzh0K4UgLPNI8=", false, function () {
  return [useLoaderData];
});
_c3 = App;
var _c, _c2, _c3;
$RefreshReg$(_c, "LiveVisualEditing$lazy");
$RefreshReg$(_c2, "LiveVisualEditing");
$RefreshReg$(_c3, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;